<template>
  <div class="user-tab-billings-plans">
    <v-card class="mb-7">
      <v-card-title>
        Available Modules
        <v-chip
          v-if="organization.billing_details && organization.billing_details.is_free_trial"
          label
          color="primary"
          class="v-chip-light-bg primary--text ms-3"
        >
          Free Trial
        </v-chip>
      </v-card-title>

      <v-card-text v-if="organization.billing_details && organization.billing_details.is_expired">
        <v-alert
          dense
          text
          color="warning"
          class="mb-0"
        >
          <p class="font-weight-semibold mb-1">
            Your subscription has expired!
          </p>
          <p class="text-sm mb-0">
            Payment for re-subscription failed. Please make your payment manually
            <a
              class="warning--text text-decoration-underline"
              @click="isPaymentDialogOpen = true"
            >
              here
            </a>. Note that choosing a different payment method can often fix the issue.
          </p>
        </v-alert>
      </v-card-text>

      <template v-if="organization.billing_details && !organization.billing_details.is_expired">
        <v-card-text v-if="organization.billing_details.payment_period === 'monthly'">
          <p
            v-if="organization.billing_details"
            class="mb-4"
          >
            Automatic renewal after {{ organization.billing_details.end_date }}
          </p>
          <v-row>
            <v-col
              v-for="plan in plansList"
              :key="plan.id"
              cols="12"
              md="6"
              lg="4"
              align-self="center"
            >
              <v-card
                outlined
                :class="{ 'primary-border': plan.subscribed_by_current_organization && plan.is_active }"
              >
                <v-card-text class="d-flex pa-3">
                  <v-icon
                    size="30"
                    class="me-3 my-auto"
                    :color="plan.subscribed_by_current_organization && plan.is_active ? 'primary' : 'secondary'"
                  >
                    {{ icons[plan.icon.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())] || icons['mdiAccountQuestionOutline'] }}
                  </v-icon>
                  <div>
                    <h5>{{ plan.name }}</h5>
                    <p class="mb-0">
                      {{ plan.is_active ? `$${plan.payment}/month` : 'Coming Soon!' }}
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-menu
                    v-if="plan.is_active"
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-if="!plan.subscribed_by_current_organization"
                        @click="createSubscription(plan)"
                      >
                        <v-list-item-title>
                          <v-icon
                            size="20"
                            class="me-2"
                          >
                            {{ icons.mdiWrenchOutline }}
                          </v-icon>
                          <span>Install</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-else
                        @click="cancelledSubscription = plan; isCancelSubscriptionDialogOpen = true"
                      >
                        <v-list-item-title>
                          <v-icon
                            size="20"
                            class="me-2"
                          >
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                          <span>Uninstall</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          <p
            v-if="organization.billing_details"
            class="mb-4"
          >
            Automatic renewal after {{ organization.billing_details.end_date }}
          </p>
          <v-alert
            dense
            text
            color="primary"
            class="mb-0"
          >
            <p class="font-weight-semibold mb-1">
              You went annual with us!
            </p>
            <p class="text-sm mb-0">
              You are currently on the annual payment plan for Clericle. Any new subscriptions to modules or member top ups can be made by contacting us
              <a
                class="primary--text text-decoration-underline"
                href="mailto:support@clericle.com?subject=[Annual User] I want to subscribe or top up members"
              >
                here
              </a>
            </p>
          </v-alert>
        </v-card-text>
      </template>

      <v-card-text
        v-if="organization.billing_details && organization.billing_details.payment_period === 'monthly'"
        class="text-right"
      >
        <a
          class="text-decoration-underline"
          @click="isAnnualSubscriptionDialogOpen = true"
        >
          Save up to 17% by going with the annual plan!
        </a>
      </v-card-text>
    </v-card>

    <v-card class="mb-7">
      <v-card-title>
        <v-row>
          <v-col class="my-auto">
            <p class="mb-2 mb-sm-0 text-no-wrap">
              Payment Methods
            </p>
          </v-col>

          <v-col align="end">
            <v-btn
              color="primary"
              @click="isPaymentMethodDialogOpen = true"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span class="d-none d-sm-block">Add Card</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="paymentMethods.length > 0">
        <v-card
          v-for="(card, index) in paymentMethods"
          :key="card.id"
          outlined
          :class="index < paymentMethods.length -1 ? 'mb-4':''"
        >
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <v-img
                v-if="['amex', 'visa', 'mastercard', 'unionpay'].includes(card.card.brand)"
                contain
                max-width="60"
                height="25"
                :src="require(`@/assets/images/logos/${card.card.brand}.png`)"
              ></v-img>
              <v-img
                v-else
                contain
                max-width="60"
                height="25"
                :src="require(`@/assets/images/pages/credit-card.png`)"
              ></v-img>
              <div class="text--primary font-weight-medium mt-2">
                <span class="text-base me-2">{{ card.billing_details.name }}</span>
              </div>
              <span>
                <span class="text-xs">**** **** **** </span>
                <span>{{ card.card.last4 }}</span>
              </span>
            </div>

            <v-spacer></v-spacer>

            <div class="text-sm-right">
              <div class="d-flex">
                <v-btn
                  v-if="!card.is_default"
                  :loading="updatePaymentMethodLoading"
                  color="primary"
                  outlined
                  class="ms-0 ms-sm-3 mb-5"
                  @click="updatePaymentMethod(card.id)"
                >
                  Make default
                </v-btn>
                <v-chip
                  v-else
                  color="primary"
                  label
                  class="v-chip-light-bg primary--text ms-0 ms-sm-3 mb-5"
                >
                  Default
                </v-chip>

                <v-btn
                  color="error"
                  outlined
                  class="ms-3 mb-5"
                  @click="paymentMethod = card; isDeleteDialogOpen = true"
                >
                  Delete
                </v-btn>
              </div>

              <p class="mb-0">
                <span class="me-1">Card expires on</span>
                <span>{{ String(card.card.exp_month).padStart(2, '0') }}/{{ String(card.card.exp_year).slice(-2) }}</span>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-else>
        <v-alert
          dense
          text
          color="warning"
          class="mb-0"
        >
          <p class="font-weight-semibold mb-1">
            You have not set a payment method!
          </p>
          <p class="text-sm mb-0">
            You need to set a default payment method in order to renew subscriptions. Learn more about Stripe's secure payments
            <a
              href="https://stripe.com/en-sg/privacy"
              target="_blank"
              class="text-decoration-underline warning--text"
            >
              here
            </a>
          </p>
        </v-alert>
      </v-card-text>
    </v-card>

    <v-card class="mb-7">
      <v-card-title class="d-flex align-center">
        <v-row>
          <v-col class="my-auto text-no-wrap text-subtitle-1 text-sm-h6">
            Members Quota ({{ organization.members_count }}/{{ organization.members_quota }})
          </v-col>

          <v-col align="end">
            <v-btn
              v-if="can('write', 'Organization')"
              color="primary"
              class="me-3"
              @click="isInviteMemberDialogOpen = true"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span class="d-none d-sm-block">Add member</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="memberColumnHeaders"
        :items="members"
        mobile-breakpoint="0"
        hide-default-footer
        class="d-none d-sm-block"
      >
        <template #[`item.member`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              {{ item.initials }}
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.email }}</span>
            </div>
          </div>
        </template>

        <template #[`item.joined_at`]="{item}">
          <div class="text-no-wrap">
            {{ item.joined_at }}
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            v-if="item.role !== 'Super'"
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="can('write', 'Organization')"
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="item.joined_at === 'Pending'">
                <v-list-item @click="resendInvitation(item.invitation_token)">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiRefresh }}
                    </v-icon>
                    <span>Resend</span>
                  </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
              </template>

              <v-list-item @click="member = item; isRemoveMemberDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Remove</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="memberSmColumnHeaders"
        :items="members"
        :expanded.sync="memberExpanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="d-block d-sm-none"
      >
        <template #[`item.member`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              {{ item.initials }}
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.email }}</span>
            </div>
          </div>
        </template>

        <template #[`item.joined_at`]="{item}">
          <div class="text-no-wrap">
            {{ item.joined_at }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <div class="d-flex">
              <p class="mt-2">
                Joined at: {{ item.joined_at }}
              </p>
              <v-spacer></v-spacer>
              <v-menu
                v-if="item.role !== 'Super'"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="can('write', 'Organization')"
                    icon
                    class="me-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <template v-if="item.joined_at === 'Pending'">
                    <v-list-item @click="resendInvitation(item.invitation_token)">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiRefresh }}
                        </v-icon>
                        <span class="caption">Resend</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>
                  </template>

                  <v-list-item @click="member = item; isRemoveMemberDialogVisible = true">
                    <v-list-item-title>
                      <v-icon
                        size="16"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span class="caption">Remove</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <v-card-text
        v-if="organization.billing_details && organization.billing_details.payment_period === 'monthly' && can('write', 'Organization')"
        class="d-flex align-center pt-5"
      >
        <v-btn
          v-if="organization"
          color="primary"
          @click="isTopUpMemberDialogVisible = true; organization.original_quota = organization.members_quota"
        >
          Top up member quota
        </v-btn>
      </v-card-text>
    </v-card>

    <!--Invite member -->
    <v-dialog
      v-model="isInviteMemberDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Invite A New Member
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isInviteMemberDialogOpen = false; inviteMemberForm.reset()"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>Please enter your member's email and we will send them an invite to join your organization</p>
          <template v-if="organization">
            <h4>Remaining member quota left: {{ organization.members_quota - organization.members_count }}</h4>
            <v-alert
              v-if="(organization.members_quota - organization.members_count) == 0"
              dense
              text
              color="warning"
              class="mt-3"
            >
              <p class="font-weight-semibold mb-1">
                Maximum members quota reached
              </p>
              <p class="text-sm mb-0">
                Please top up your organization's members quota before continuing.
              </p>
            </v-alert>
          </template>
          <v-form
            ref="inviteMemberForm"
            v-model="inviteMemberValid"
          >
            <v-text-field
              v-model="memberEmail"
              dense
              outlined
              hide-details="auto"
              :rules="[validators.required, validators.emailValidator]"
              placeholder="Enter email here"
              class="mt-5"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="inviteMemberLoading"
            :disabled="inviteMemberLoading || !inviteMemberValid || (organization.members_quota - organization.members_count) == 0"
            class="mt-3"
            @click="createInvitation()"
          >
            Send invitation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete member -->
    <v-dialog
      v-model="isRemoveMemberDialogVisible"
      width="500"
      persistent
    >
      <v-card v-if="member">
        <v-card-title class="d-flex align-center mv-4">
          Remove {{ member.email }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRemoveMemberDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This member will no longer be a team member under this organization.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="removeMemberLoading"
            :disabled="removeMemberLoading"
            class="mt-3"
            @click="deleteMember()"
          >
            Remove member
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Top up member -->
    <v-dialog
      v-model="isTopUpMemberDialogVisible"
      width="500"
      persistent
    >
      <v-card v-if="organization">
        <v-card-title class="d-flex align-center mv-4">
          Top Up Member Quota
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isTopUpMemberDialogVisible = false; organization.members_quota = organization.original_quota"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="d-flex align-center">
          <p class="mb-0">
            Change members quota. You will be paying <br>
            <span class="primary--text">${{ organization.members_quota * 6 }}.00</span> starting on the next billing cycle.
          </p>
          <v-spacer></v-spacer>
          <v-form ref="membersQuotaForm">
            <v-text-field
              v-model="organization.members_quota"
              outlined
              dense
              reverse
              :min="organization.members_count"
              type="number"
              hide-details="auto"
              prefix="users"
              class="quota-input"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="isLoading"
            :disabled="isLoading"
            class="mt-3"
            @click="updateOrganization()"
          >
            Change members quota
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        Payment History
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="payments"
        hide-default-footer
        fixed-header
        :items-per-page="50"
        mobile-breakpoint="0"
        :loading="fetchLoading"
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            class="me-2"
            :loading="downloadPaymentReceiptLoading"
            @click="downloadPaymentReceipt(item)"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmColumnHeaders"
        :items="payments"
        hide-default-footer
        fixed-header
        :items-per-page="50"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        :loading="fetchLoading"
        class="text-no-wrap d-block d-sm-none"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Payment Date: {{ item.created_at }}
            </p>
            <p>Description:</p>
            <div class="d-flex">
              <p
                class="text-wrap"
                v-html="item.Description"
              ></p>
              <v-spacer></v-spacer>
              <v-btn
                icon
                class="me-2"
                :loading="downloadPaymentReceiptLoading"
                @click="downloadPaymentReceipt(item)"
              >
                <v-icon>{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </div>
          </td>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            lg="2"
            md="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="invoice-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            lg="10"
            md="9"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchPayments"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="isAnnualSubscriptionDialogOpen"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          Let's Go Annual!
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isAnnualSubscriptionDialogOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="mb-5">
            Pay now and get access to our modules for the whole year. Send us a message and we'll get back to you shortly.
          </p>
          <v-form ref="inquiryForm">
            <v-text-field
              v-model="localUserData.nickname"
              outlined
              dense
              label="Name"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-text-field
              v-model="localUserData.email"
              outlined
              dense
              label="Email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-text-field
              v-model="annualPaymentInquiry.subject"
              outlined
              dense
              label="Subject"
              disabled
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-textarea
              v-model="annualPaymentInquiry.message"
              outlined
              hide-details
              rows="5"
              label="Type your inquiry here..."
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="isAnnualSubscriptionLoading"
            :disabled="isAnnualSubscriptionLoading"
            class="mt-3"
            @click="sendMessage()"
          >
            Send Message
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isCancelSubscriptionDialogOpen"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title
          v-if="cancelledSubscription"
          class="d-flex align-center mv-4"
        >
          Uninstall Module?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCancelSubscriptionDialogOpen = false; cancelledSubscription = null"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="cancelledSubscription">
          <div class="text-center mb-4">
            <template>
              <v-chip
                label
                color="primary"
                class="v-chip-light-bg primary--text font-weight-semibold"
              >
                {{ cancelledSubscription.name }} - ${{ cancelledSubscription.payment }}/month
              </v-chip>
            </template>
          </div>
          <div v-if="cancelledSubscription.name == 'Billing Management' && expensesSubscription.subscribed_by_current_organization && expensesSubscription.is_active">
            <p class="font-weight-semibold">
              These modules will also be uninstalled together
            </p>
            <div class="mb-4">
              <v-chip
                label
                color="primary"
                class="v-chip-light-bg primary--text font-weight-semibold"
              >
                {{ expensesSubscription.name }} - ${{ expensesSubscription.payment }}/month
              </v-chip>
            </div>
          </div>
          <p class="font-weight-semibold">
            When you uninstall a module
          </p>
          <p class="text-sm mb-0">
            <ol>
              <li class="mb-1">
                You will only lose access to the module but your data will still remain until you choose to re-install.
              </li>
              <li class="mb-1">
                After uninstalling, there will be a grace period of 30 days before you can re-install this module.
              </li>
            </ol>
          </p>
          <p class="font-weight-semibold my-5">
            We're sad to see you go 😢 Would you mind telling us the reason for your cancellation?
          </p>
          <v-textarea
            v-model="cancellationReason"
            outlined
            hide-details
            rows="5"
            label="Type your feedback here..."
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="isCancelSubscriptionLoading"
            :disabled="isCancelSubscriptionLoading"
            class="mt-3"
            @click="deleteSubscription()"
          >
            Cancel subscription
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isPaymentMethodDialogOpen"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4 mb-4">
          {{ paymentMethod.id ? 'Edit' : 'New' }} Payment Method
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isPaymentMethodDialogOpen = false; savePaymentMethodForm.reset()"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="savePaymentMethodForm"
            v-model="savePaymentMethodValid"
          >
            <v-text-field
              v-model="paymentMethod.card_name"
              outlined
              dense
              label="Card Name"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <StripeElement
              :element="cardElement"
              style="border: 2px solid rgba(94, 86, 105, 0.14);"
              class="pa-3 rounded"
            />
          </v-form>
          <small>Guaranteed secure payments with
            <a
              href="https://stripe.com/docs/security/stripe"
              target="_blank"
              class="text-decoration-none"
            >
              Stripe
            </a>
          </small>
          <v-checkbox
            v-model="paymentMethod.is_default"
            label="Make as default payment method"
            color="primary"
            value="true"
            hide-details
            class="mb-3"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="savePaymentMethodLoading"
            :disabled="savePaymentMethodLoading || !savePaymentMethodValid"
            @click="savePaymentMethod()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDeleteDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          v-if="paymentMethod.card"
          class="d-flex align-center mv-4"
        >
          Delete Card **** **** **** {{ paymentMethod.card.last4 }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteDialogOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This card will be permanently removed from this list. It will no longer be available for use for payments in Clericle.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deletePaymentMethodLoading"
            :disabled="deletePaymentMethodLoading"
            class="mt-3"
            @click="detachPaymentMethod(paymentMethod.id)"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isPaymentDialogOpen"
      width="500"
      persistent
    >
      <v-card v-if="organization.billing_details">
        <v-card-title class="d-flex align-center mv-4">
          Renew Subscription
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isPaymentDialogOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-5 mb-3">
          <h2 class="text-center">
            Amount Due: ${{ (organization.billing_details.total - organization.billing_details.discount).toFixed(2) }}
          </h2>
        </v-card-text>

        <v-card-text>
          <v-radio-group
            v-model="paymentCardId"
            class="mt-0"
            hide-details
          >
            <template v-if="paymentMethods.length > 0">
              <v-card
                v-for="(card, index) in paymentMethods"
                :key="card.id"
                outlined
                :class="index < paymentMethods.length -1 ? 'mb-4':''"
              >
                <v-card-text class="d-flex align-sm-center flex-column flex-sm-row pa-3">
                  <div class="mb-3 mb-sm-0 d-flex align-center">
                    <v-img
                      v-if="['amex', 'visa', 'mastercard', 'unionpay'].includes(card.card.brand)"
                      contain
                      max-width="60"
                      height="25"
                      :src="require(`@/assets/images/logos/${card.card.brand}.png`)"
                    ></v-img>
                    <v-img
                      v-else
                      contain
                      max-width="60"
                      height="25"
                      :src="require(`@/assets/images/pages/credit-card.png`)"
                    ></v-img>
                    <span class="ml-4">
                      <span class="text-xs">**** **** **** </span>
                      <span>{{ card.card.last4 }}</span>
                    </span>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="text-sm-right d-flex align-center">
                    <v-radio :value="card.id"></v-radio>
                  </div>
                </v-card-text>
              </v-card>
            </template>
            <template v-else>
              <v-alert
                dense
                text
                color="warning"
                class="mb-0"
              >
                <p class="font-weight-semibold mb-1">
                  You have not set a payment method!
                </p>
                <p class="text-sm mb-0">
                  You have not set up a payment method yet! Click
                  <a
                    class="warning--text text-decoration-underline"
                    @click="isPaymentDialogOpen = false; isPaymentMethodDialogOpen = true"
                  >
                    here
                  </a>
                  to start setting up now.
                </p>
              </v-alert>
            </template>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="paymentLoading"
            :disabled="paymentLoading"
            class="mt-3"
            @click="updateBilling()"
          >
            Pay with this card
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  computed,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
  mdiDownload,
  mdiRefresh,
  mdiDeleteOutline,
  mdiWrenchOutline,
  mdiDotsVertical,
  mdiNotebookEditOutline,
  mdiFaceAgent,
  mdiBookOpen,
  mdiHumanGreeting,
  mdiMonitorDashboard,
  mdiAccountQuestionOutline,
  mdiPointOfSale,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import encryptionService from '@/services/encryption.service'
import { useStripe, StripeElement } from 'vue-use-stripe'

export default {
  components: { StripeElement },
  setup() {
    // Initializers
    const can = inject('can')
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const userData = computed(() => store.state.settings)
    const organizationData = computed(() => store.state.organization)

    // Properties
    const localUserData = ref({ ...userData.value })
    const organization = ref({ ...organizationData.value })

    const members = ref([])
    const member = ref({ email: '' })
    const memberEmail = ref(null)
    const isLoading = ref(false)
    const isTopUpMemberDialogVisible = ref(false)
    const isRemoveMemberDialogVisible = ref(false)
    const removeMemberLoading = ref(false)

    const isInviteMemberDialogOpen = ref(false)
    const inviteMemberValid = ref(false)
    const inviteMemberLoading = ref(false)
    const inviteMemberForm = ref(null)

    const paymentMethod = ref({})
    const paymentMethods = ref([])
    const savePaymentMethodValid = ref(false)
    const savePaymentMethodLoading = ref(false)
    const savePaymentMethodForm = ref(null)
    const {
      stripe,
      elements: [cardElement],
    } = useStripe({
      key: process.env.VUE_APP_STRIPE_PUBLIC_KEY || '',
      elements: [
        {
          type: 'card',
          options: {
            hidePostalCode: true,
            style: {
              base: {
                color: '#019267',
                fontSize: '18px',
                '::placeholder': {
                  textTransform: 'capitalize',
                  color: 'rgba(94, 86, 105, 0.87)',
                },
              },
            },
          },
        },
      ],
    })
    const isPaymentMethodDialogOpen = ref(false)
    const updatePaymentMethodLoading = ref(false)
    const isDeleteDialogOpen = ref(false)
    const deletePaymentMethodLoading = ref(false)

    const payments = ref([])
    const isPaymentDialogOpen = ref(false)
    const paymentCardId = ref(null)
    const paymentLoading = ref(false)
    const downloadPaymentReceiptLoading = ref(false)

    const plansList = ref([])
    const cancelledSubscription = ref(null)
    const cancellationReason = ref(null)
    const isCancelSubscriptionDialogOpen = ref(false)
    const isCancelSubscriptionLoading = ref(false)

    const isAnnualSubscriptionDialogOpen = ref(false)
    const isAnnualSubscriptionLoading = ref(false)
    const annualPaymentInquiry = ref({
      subject: "I'd like to Go Annual!",
      message: null,
    })

    // Table Handlers
    const fetchLoading = ref(false)
    const expanded = ref([])
    const memberExpanded = ref([])
    const listLengthOptions = ref([5, 25, 50])
    const tablePagination = ref({
      current_page: 1,
      per: 5,
      total_count: 0,
      total_page: 0,
    })
    const tableColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'payment_number', sortable: false },
      { text: 'DESCRIPTION', value: 'description', sortable: false },
      { text: 'PAYMENT DATE', value: 'created_at', sortable: false },
      { text: 'TOTAL', value: 'total', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const tableSmColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'payment_number', sortable: false },
      { text: 'TOTAL', value: 'total', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]
    const memberColumnHeaders = [
      { text: 'MEMBER', value: 'member', sortable: false },
      { text: 'ROLE', value: 'role', sortable: false },
      { text: 'JOINED AT', value: 'joined_at', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const memberSmColumnHeaders = [
      { text: 'MEMBER', value: 'member', sortable: false },
      { text: 'ROLE', value: 'role', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Computed
    const expensesSubscription = computed(() => plansList.value.find(plan => plan.name === 'Expenses'))

    // Methods
    const fetchSubscriptions = () => {
      store
        .dispatch('settingsStore/fetchSubscriptions')
        .then(response => {
          plansList.value = response.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const fetchPaymentMethods = () => {
      store
        .dispatch('settingsStore/fetchPaymentMethods')
        .then(response => {
          paymentMethods.value = response.data.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const fetchPayments = () => {
      fetchLoading.value = true
      store
        .dispatch('settingsStore/fetchPayments', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
        })
        .then(response => {
          const { data, pagination } = response.data
          payments.value = data
          tablePagination.value = pagination
          fetchLoading.value = false
        })
        .catch(error => {
          fetchLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchPayments()
    }
    const createSubscription = plan => {
      store
        .dispatch('settingsStore/createSubscription', { subscription_type_id: plan.id })
        .then(response => {
          const encryptedModules = localStorage.getItem('organizationModules')
          const modules = encryptedModules ? JSON.parse(encryptionService.decrypt(encryptedModules)) : null
          modules.push(plan.name)
          const encryptModules = encryptionService.encrypt(JSON.stringify(modules))
          localStorage.setItem('organizationModules', encryptModules)

          window.location.reload()
          snackbarService.success(response.data.message)
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const deleteSubscription = () => {
      isCancelSubscriptionLoading.value = true
      const subscription = organization.value.subscriptions.find(sub => sub.subscription_type.id === cancelledSubscription.value.id)

      store
        .dispatch('settingsStore/deleteSubscription', { id: subscription.id, reason: cancellationReason.value })
        .then(response => {
          const encryptedModules = localStorage.getItem('organizationModules')
          let modules = encryptedModules ? JSON.parse(encryptionService.decrypt(encryptedModules)) : null
          modules = modules.filter(submodule => submodule !== subscription.subscription_type.name)
          if (subscription.subscription_type.name === 'Billing Management' && expensesSubscription.value.subscribed_by_current_organization && expensesSubscription.value.is_active) {
            modules.splice(modules.indexOf('Expenses'), 1)
          }
          const encryptModules = encryptionService.encrypt(JSON.stringify(modules))
          localStorage.setItem('organizationModules', encryptModules)

          window.location.reload()
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          isCancelSubscriptionLoading.value = false
          snackbarService.error(error.response.data.errors[0] || error.response.data.message)
        })
    }
    const updatePaymentMethod = paymentMethodId => {
      updatePaymentMethodLoading.value = true
      store
        .dispatch('settingsStore/updatePaymentMethod', { id: paymentMethodId })
        .then(async response => {
          await fetchPaymentMethods()
          savePaymentMethodForm.value.reset()
          cardElement.value.clear()
          snackbarService.success(response.data.message)
          updatePaymentMethodLoading.value = false
        })
        .catch(error => {
          updatePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const savePaymentMethod = () => {
      savePaymentMethodLoading.value = true
      store
        .dispatch('settingsStore/createPaymentMethod')
        .then(async response => {
          const clientSecret = response.data.data.client_secret
          await stripe.value?.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement.value,
              billing_details: {
                name: paymentMethod.value.card_name,
              },
            },
          }).then(async res => {
            savePaymentMethodLoading.value = false

            if (res.error) snackbarService.error(res.error.message)
            else {
              if (paymentMethod.value.is_default || paymentMethods.value.length === 0) await updatePaymentMethod(res.setupIntent.payment_method)

              isPaymentMethodDialogOpen.value = false
              fetchPaymentMethods()
              savePaymentMethodForm.value.reset()
              cardElement.value.clear()
              snackbarService.success('Card added successfully!')
            }
          })
        })
        .catch(() => {
          savePaymentMethodLoading.value = false
          snackbarService.error('Something went wrong while adding this card. Please try again later.')
        })
    }
    const detachPaymentMethod = paymentMethodId => {
      deletePaymentMethodLoading.value = true
      store
        .dispatch('settingsStore/detachPaymentMethod', { id: paymentMethodId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPaymentMethods()
          isDeleteDialogOpen.value = false
          deletePaymentMethodLoading.value = false
        })
        .catch(error => {
          deletePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting item. Please refresh!')
        })
    }
    const fetchMembers = () => {
      store
        .dispatch('settingsStore/fetchMembers')
        .then(response => {
          members.value = response.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const createInvitation = () => {
      inviteMemberLoading.value = true
      store
        .dispatch('settingsStore/createInvitation', { email: memberEmail.value })
        .then(response => {
          isInviteMemberDialogOpen.value = false
          inviteMemberLoading.value = false
          inviteMemberForm.value.reset()
          snackbarService.success(response.data.message)
          fetchMembers()
        })
        .catch(error => {
          inviteMemberLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const resendInvitation = token => {
      store
        .dispatch('settingsStore/resendInvitation', { invitation_token: token })
        .then(response => {
          snackbarService.success(response.data.message)
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const deleteMember = () => {
      removeMemberLoading.value = true
      store
        .dispatch('settingsStore/deleteMember', { id: member.value.id })
        .then(response => {
          isRemoveMemberDialogVisible.value = false
          removeMemberLoading.value = false
          snackbarService.success(response.data.message)
          fetchMembers()
        })
        .catch(error => {
          removeMemberLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const updateOrganization = () => {
      isLoading.value = true
      const formData = new FormData()
      Object.entries(organization.value).forEach(([key, value]) => {
        if (value) formData.append(key, value)
      })

      store
        .dispatch('updateOrganization', formData)
        .then(response => {
          const { data, message } = response.data

          delete data.permissions
          store.dispatch('setOrganization', data)

          isLoading.value = false
          isTopUpMemberDialogVisible.value = false
          snackbarService.success(message)
        })
        .catch(error => {
          const { errors, message } = error.response.data

          isLoading.value = false
          if (errors.length > 0) snackbarService.error('Please fill in the required fields.')
          else snackbarService.error(message)
        })
    }
    const downloadPaymentReceipt = payment => {
      downloadPaymentReceiptLoading.value = true
      store
        .dispatch('settingsStore/downloadPayment', { id: payment.id })
        .then(response => {
          const url = window.URL.createObjectURL(response)
          const a = document.createElement('a')
          a.href = url
          a.download = `${payment.payment_number}-${payment.description}.pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          downloadPaymentReceiptLoading.value = false
        })
        .catch(error => {
          downloadPaymentReceiptLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading payment receipt. Please refresh!')
        })
    }
    const updateBilling = () => {
      paymentLoading.value = true
      store
        .dispatch('settingsStore/updateBilling', {
          id: organization.value.billing_details.id,
          payment_method_id: paymentCardId.value,
        })
        .then(response => {
          paymentLoading.value = false
          isPaymentDialogOpen.value = false
          paymentCardId.value = null
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          paymentLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while making payment. Please refresh!')
        })
    }
    const sendMessage = () => {
      isAnnualSubscriptionLoading.value = true
      store
        .dispatch('sendMessage', {
          name: localUserData.value.name,
          email: localUserData.value.email,
          subject: annualPaymentInquiry.value.subject,
          message: annualPaymentInquiry.value.message,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          isAnnualSubscriptionDialogOpen.value = false
          isAnnualSubscriptionLoading.value = false
        })
        .catch(error => {
          isAnnualSubscriptionLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending message. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchSubscriptions()
      fetchPaymentMethods()
      fetchPayments()
      fetchMembers()
    })

    return {
      // Initializers
      can,

      // Computed
      expensesSubscription,

      // Properties
      localUserData,
      organization,

      member,
      members,
      memberEmail,
      isLoading,
      isTopUpMemberDialogVisible,
      isRemoveMemberDialogVisible,
      removeMemberLoading,

      isInviteMemberDialogOpen,
      inviteMemberValid,
      inviteMemberLoading,
      inviteMemberForm,

      paymentMethod,
      paymentMethods,
      savePaymentMethodValid,
      savePaymentMethodLoading,
      savePaymentMethodForm,
      cardElement,
      isPaymentMethodDialogOpen,
      updatePaymentMethodLoading,
      isDeleteDialogOpen,
      deletePaymentMethodLoading,

      payments,
      isPaymentDialogOpen,
      paymentCardId,
      paymentLoading,
      downloadPaymentReceiptLoading,

      plansList,
      cancelledSubscription,
      cancellationReason,
      isCancelSubscriptionDialogOpen,
      isCancelSubscriptionLoading,

      annualPaymentInquiry,
      isAnnualSubscriptionDialogOpen,
      isAnnualSubscriptionLoading,

      // Table Handlers
      fetchLoading,
      expanded,
      memberExpanded,
      listLengthOptions,
      tablePagination,
      tableColumnHeaders,
      tableSmColumnHeaders,
      memberColumnHeaders,
      memberSmColumnHeaders,

      // Validators
      validators: {
        required,
        emailValidator,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiClose,
        mdiDownload,
        mdiRefresh,
        mdiDeleteOutline,
        mdiWrenchOutline,
        mdiDotsVertical,
        mdiNotebookEditOutline,
        mdiFaceAgent,
        mdiBookOpen,
        mdiHumanGreeting,
        mdiMonitorDashboard,
        mdiAccountQuestionOutline,
        mdiPointOfSale,
      },

      // Methods
      fetchPayments,
      listLengthChange,
      createSubscription,
      deleteSubscription,
      updatePaymentMethod,
      savePaymentMethod,
      detachPaymentMethod,
      createInvitation,
      resendInvitation,
      deleteMember,
      updateOrganization,
      downloadPaymentReceipt,
      updateBilling,
      sendMessage,
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/mixins.scss';

  .primary-border {
    border: 2px solid var(--v-primary-base) !important;
  }
</style>

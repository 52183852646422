<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            size="120"
            rounded
          >
            <span class="font-weight-semibold text-5xl">{{ userData.initials }}</span>
          </v-avatar>
          <span class="mb-2">{{ userData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Nickname:</span>
              <span class="text--secondary">{{ userData.nickname }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            block
            color="primary"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit Details
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog -->
      <v-dialog
        v-model="isBioDialogOpen"
        max-width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4 mb-4">
            Edit User Information
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isBioDialogOpen = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="mt-5">
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="userData.name"
                    outlined
                    dense
                    label="Name"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="userData.nickname"
                    prefix="@"
                    outlined
                    dense
                    label="Nickname"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="userData.email"
                    outlined
                    dense
                    label="Email"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="mt-6">
            <v-btn
              color="primary"
              block
              :loading="isLoading"
              :disabled="isLoading"
              @click="updateSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiClose,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
} from '@mdi/js'
import { ref, inject } from '@vue/composition-api'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const isBioDialogOpen = ref(false)
    const isLoading = ref(false)

    // Methods
    const updateSettings = () => {
      isLoading.value = true
      store
        .dispatch('updateSettings', { user: props.userData })
        .then(response => {
          const { data, message } = response.data

          delete data.permissions
          store.dispatch('setSettings', data)

          isLoading.value = false
          isBioDialogOpen.value = false
          snackbarService.success(message)
        })
        .catch(error => {
          isLoading.value = false
          snackbarService.error(error.response.data.errors[0] || error.response.data.message)
        })
    }

    return {
      // Properties
      isBioDialogOpen,
      isLoading,

      // Icons
      icons: {
        mdiCheck,
        mdiClose,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },

      // Methods
      updateSettings,
    }
  },
}
</script>

<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <settings-bio-panel
          :user-data="userData"
        ></settings-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiDomain }}
            </v-icon>
            <span>Organization</span>
          </v-tab>

          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiLockOutline }}
            </v-icon>
            <span>Security</span>
          </v-tab>

          <v-tab v-if="can('read', 'Billing')">
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiCreditCardOutline }}
            </v-icon>
            <span>Billing & Plan</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
          touchless
        >
          <v-tab-item>
            <settings-organization></settings-organization>
          </v-tab-item>
          <v-tab-item>
            <settings-security></settings-security>
          </v-tab-item>
          <v-tab-item v-if="can('read', 'Billing')">
            <settings-billing></settings-billing>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import {
  mdiDomain,
  mdiLockOutline,
  mdiCreditCardOutline,
} from '@mdi/js'
import SettingsBioPanel from './SettingsBioPanel.vue'
import SettingsOrganization from './SettingsOrganization.vue'
import SettingsSecurity from './SettingsSecurity.vue'
import SettingsBilling from './SettingsBilling.vue'

export default {
  components: {
    SettingsBioPanel,
    SettingsOrganization,
    SettingsSecurity,
    SettingsBilling,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const can = inject('can')

    // Properties
    const userData = computed(() => store.state.settings)
    const userTab = ref(null)

    return {
      // Properties
      can,
      userTab,
      userData,

      // Icons
      icons: {
        mdiDomain,
        mdiLockOutline,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title>
        Change Password
      </v-card-title>

      <v-card-text>
        <v-alert
          dense
          text
          color="primary"
        >
          <p class="font-weight-semibold mb-1">
            Ensure that these requirements are met
          </p>
          <p class="text-sm mb-0">
            Minimum 8 characters long, contains an uppercase and a symbol
          </p>
        </v-alert>

        <v-form
          class="multi-col-validation"
          @submit.prevent="updatePassword"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="password"
                outlined
                dense
                label="New Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="passwordConfirmation"
                outlined
                dense
                label="Confirm New Password"
                :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                type="submit"
                color="primary"
                :loading="isLoading"
                :disabled="password == null || passwordConfirmation == null || isLoading"
              >
                Change Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, inject } from '@vue/composition-api'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const password = ref(null)
    const passwordConfirmation = ref(null)
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const isLoading = ref(false)

    // Methods
    const updatePassword = () => {
      isLoading.value = true
      store
        .dispatch('updateSettings', { user: { password: password.value, password_confirmation: passwordConfirmation.value } })
        .then(response => {
          isLoading.value = false
          password.value = null
          passwordConfirmation.value = null
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          isLoading.value = false
          snackbarService.error(error.response.data.errors[0] || error.response.data.message)
        })
    }

    return {
      // Properties
      password,
      passwordConfirmation,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isLoading,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // Methods
      updatePassword,
    }
  },
}
</script>

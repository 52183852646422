<template>
  <div class="mb-7">
    <v-card class="mb-5">
      <v-form
        class="multi-col-validation"
        @submit.prevent="updateOrganization"
      >
        <v-card-title>
          Details
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.name"
                outlined
                dense
                label="Name"
                :rules="organizationRules"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.display_name"
                outlined
                dense
                label="Display Name"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.email"
                outlined
                dense
                label="Email"
                :rules="[validators.required, validators.emailValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.roc_number"
                outlined
                dense
                label="ROC Number"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="organization.description"
                outlined
                rows="3"
                label="Description"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="organization.address"
                outlined
                rows="3"
                label="Address"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.contact"
                outlined
                dense
                label="Contact number"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="organization.website"
                outlined
                dense
                label="Website"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text
          v-if="can('write', 'Organization')"
          class="d-flex"
        >
          <v-btn
            color="primary"
            class="me-3 mt-3"
            type="submit"
            :loading="isLoading"
            :disabled="isLoading"
          >
            <div class="mr-1">
              Save
            </div>
            <div class="d-none d-sm-block">
              changes
            </div>
          </v-btn>
          <v-btn
            color="error"
            outlined
            class="mt-3"
            type="reset"
            :disabled="isLoading"
            @click.prevent="resetForm"
          >
            <div class="mr-1">
              Cancel
            </div>
            <div class="d-none d-sm-block">
              changes
            </div>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mb-5">
      <v-card-text class="d-flex pt-6">
        <v-row>
          <v-col
            cols="12"
            xl="2"
            sm="3"
            class="text-center"
          >
            <v-avatar
              rounded
              size="120"
              class="me-6"
            >
              <v-img :src="organization.logo_url">
                <template v-slot:placeholder>
                  <v-sheet>
                    <v-img
                      src="@/assets/images/logo.png"
                    ></v-img>
                  </v-sheet>
                </template>
              </v-img>
            </v-avatar>
          </v-col>

          <v-col
            cols="12"
            xl="10"
            sm="9"
          >
            <v-alert
              dense
              text
              color="primary"
            >
              <p class="font-weight-semibold mb-1">
                Ensure that these requirements are met
              </p>
              <p class="text-sm mb-0">
                Only use JPG, JPEG or PNG files with transparent background. File should be max size of 5MB with preferred dimensions of 250x250.
              </p>
            </v-alert>
            <v-btn
              v-if="can('write', 'Organization')"
              color="primary"
              class="me-3"
              @click="$refs.refInputEl.click()"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">Upload new logo</span>
            </v-btn>

            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg"
              hidden
              @change="editLogo($event)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog
        v-model="openCropperDialog"
        width="500"
      >
        <v-card class="pa-3">
          <VueCropper
            v-if="selectedFile"
            ref="CropperRef"
            class="cropper-height"
            :aspect-ratio="1"
            :src="selectedFile"
          ></VueCropper>
          <v-sheet v-else>
            <v-skeleton-loader type="image" />
          </v-sheet>
          <v-card-actions class="px-0 pb-0 pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              class="me-3"
              @click="openCropperDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              class="primary"
              :loading="isCropLoading"
              :disabled="isCropLoading"
              @click="updateLogo()"
            >
              Crop
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-card>
      <v-card-title>
        Module Settings
      </v-card-title>

      <v-card-text>
        <div class="d-sm-flex align-center">
          <div class="d-flex align-center">
            <p class="mb-0">
              Add prefix to your billing records
            </p>

            <v-tooltip
              :right="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="ml-1 my-auto hover-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <p class="my-2">
                Eg. if you input CLC and save, your receipts will become CLC-R2022-X
              </p>
              <p class="mb-2">
                Only applies if you have subscribed to the Billing Management module
              </p>
            </v-tooltip>
          </div>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="organization.record_prefix"
            outlined
            dense
            :rules="prefixRules"
            hide-details="auto"
            :class="{ 'mt-3': $vuetify.breakpoint.xsOnly }"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="mt-3"
          :loading="isSettingLoading"
          :disabled="isSettingLoading"
          @click="updatePrefix"
        >
          Save Changes
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import {
  mdiCloudUploadOutline,
  mdiPlus,
  mdiClose,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiRefresh,
  mdiAlertCircleOutline,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const can = inject('can')
    const snackbarService = inject('snackbarService')
    const organizationData = computed(() => store.state.organization)

    // Properties
    const organization = ref({ ...organizationData.value })
    const isLoading = ref(false)
    const isSettingLoading = ref(false)

    const CropperRef = ref(null)
    const selectedFile = ref('')
    const openCropperDialog = ref(false)
    const isCropLoading = ref(false)

    // Validators
    const prefixRules = [
      v => (v && v.length <= 5) || 'Prefix cannot be more than 5 characters',
    ]
    const organizationRules = [
      v => !!v || 'This field is required',
      v => /^[a-zA-Z]/.test(v) || 'Must start with letter',
      v => /^[0-9a-zA-Z. ]+$/.test(v) || 'Must be alphanumeric',
      v => /[0-9a-zA-Z. ]{4,}/.test(v) || 'Must be at least 4 characters or more',
      v => !/[.]{2,}/.test(v) || 'Must not contain a sequence of .',
      v => /^\S+(?: \S+)*$/.test(v) || 'Must not contain consecutive or end with white-space',
    ]

    // Methods
    const updateOrganization = () => {
      isLoading.value = true
      const formData = new FormData()
      Object.entries(organization.value).forEach(([key, value]) => {
        if (value) formData.append(key, value)
      })

      store
        .dispatch('updateOrganization', formData)
        .then(response => {
          const { data, message } = response.data

          delete data.permissions
          store.dispatch('setOrganization', data)

          isLoading.value = false
          isCropLoading.value = false
          openCropperDialog.value = false
          snackbarService.success(message)
        })
        .catch(error => {
          const { errors, message } = error.response.data

          isLoading.value = false
          isCropLoading.value = false
          openCropperDialog.value = false
          if (errors.length > 0) snackbarService.error('Please fill in the required fields.')
          else snackbarService.error(message)
        })
    }
    const editLogo = event => {
      const file = event.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(event.target.value)) {
        snackbarService.error('The file type must be one of jpeg, jpg or png.')

        return
      }
      if (file.size / 1024 / 1024 > 2) {
        snackbarService.error('Please select a file that is less than 2MB.')

        return
      }
      if (typeof FileReader !== 'function') {
        snackbarService.error('Sorry, FileReader API not supported. Please try a different browser.')

        return
      }

      openCropperDialog.value = true
      const reader = new FileReader()
      reader.onload = content => {
        selectedFile.value = content.target.result
        if (CropperRef.value) CropperRef.value.replace(selectedFile.value)
      }
      reader.readAsDataURL(file)
    }
    const updateLogo = async () => {
      isCropLoading.value = true
      organization.value.logo_url = CropperRef.value.getCroppedCanvas().toDataURL()
      await CropperRef.value.getCroppedCanvas().toBlob(blob => {
        organization.value.logo = blob
        updateOrganization()
      })
    }
    const resetForm = () => store.dispatch('fetchOrganization')
    const updatePrefix = () => {
      isSettingLoading.value = true
      store
        .dispatch('updateOrganization', { record_prefix: organization.value.record_prefix })
        .then(response => {
          const { data, message } = response.data

          delete data.permissions
          store.dispatch('setOrganization', data)

          isSettingLoading.value = false
          snackbarService.success(message)
        })
        .catch(error => {
          const { errors } = error.response.data

          isSettingLoading.value = false
          snackbarService.error(errors[0])
        })
    }

    return {
      // Initializers
      can,

      // Properties
      organization,
      isLoading,
      isSettingLoading,

      CropperRef,
      selectedFile,
      openCropperDialog,
      isCropLoading,

      // Validators
      validators: {
        required,
        emailValidator,
      },
      prefixRules,
      organizationRules,

      // Icons
      icons: {
        mdiCloudUploadOutline,
        mdiPlus,
        mdiClose,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiRefresh,
        mdiAlertCircleOutline,
      },

      // Methods
      updateOrganization,
      editLogo,
      updateLogo,
      resetForm,
      updatePrefix,
    }
  },
}
</script>

<style>
  .quota-input {
    width: 9em;
  }
</style>
